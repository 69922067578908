<template>
  <div class="Maintenance">
    <img src="../assets/images/xtwhz.png" alt=""/>
  </div>

</template>

<script setup>

</script>

<style scoped lang="less">
.Maintenance{
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #14181A;
  img{
    width: 249px;
    height: 265px;
    margin-top: -200px;
  }
  p{

    font-size: 16px;
    font-weight: 500;

    color: #A4A9B2;
    margin-top: 36px;
  }
}
</style>
